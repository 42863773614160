import { user } from "./index";

/** 나의 정보 조회 */

export const getUser = (reqData) => {
  return user
    .get(`/userR?userIndex=${reqData.userIndex || 0}`)
    .then((res) => {
      return res.data[0];
    })
    .catch((err) => {
      return err;
    });
};
/** 나의 정보 저장 */

export const postUser = (reqData) => {
  const req = {
    data: reqData,
  };
  return user
    .post("/userS", req)
    .then((res) => {
      return res.data[0];
    })
    .catch((err) => {
      return err;
    });
};
/** 나의 정보 수정 */

export const postUserUpdate = (reqData) => {
  const req = {
    data: reqData,
  };
  return user
    .post("/userU", req)
    .then((res) => {
      return res.data[0];
    })
    .catch((err) => {
      return err;
    });
};
