import { injectionNoticeSetting } from "./index";

/** 알림 설정 조회 */
export const getInjectionNoticeSetting = (reqData) => {
  return injectionNoticeSetting
    .get(`/injectionNoticeSettingR?userIndex=${reqData.userIndex || 0}`)
    .then((res) => {
      return res.data[0];
    })
    .catch((err) => {
      return err;
    });
};

/** 알림 기록 insert */
export const postInsertInjectionNoticeSetting = (reqData) => {
  const req = {
    data: reqData,
  };
  return injectionNoticeSetting
    .post("/injectionNoticeSettingS", req)
    .then((res) => {
      return res.data[0];
    })
    .catch((err) => {
      return err;
    });
};

/** 알림 기록 update */
export const postUpdateInjectionNoticeSetting = (reqData) => {
  const req = {
    data: reqData,
  };
  return injectionNoticeSetting
    .post("/injectionNoticeSettingU", req)
    .then((res) => {
      return res.data[0];
    })
    .catch((err) => {
      return err;
    });
};
