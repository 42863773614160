/**
 * login 처리~
 */
import router from "@/router";
import store from "@/store/index";

export default (to, from, next) => {
  const userKey = to.query.userkey;
  // 추가 {} / {유저키}
  if (!userKey) {
    alert("userKey가 없습니다.");
    store.dispatch("changeUserKey", null);
    router.push({ name: "404" });
  } else if (userKey.includes('유저키')) {
    alert("userKey에 [유저키]를 넣을수 없습니다.");
    store.dispatch("changeUserKey", null);
    router.push({ name: "404" });
  } else if (userKey.includes('{')) {
    alert("userKey에 [{값]을 넣을수 없습니다.");
    store.dispatch("changeUserKey", null);
    router.push({ name: "404" });
  } else {
    store.dispatch("changeUserKey", userKey);
  }
  console.log(userKey);
  if (
    localStorage.getItem("userInfo") != null &&
    localStorage.getItem("userInfo").length > 0
  ) {
    next();
  } else {
    // 자동로그인 처리(로그인 안함)
    next();
    // localStorage.removeItem("userInfo");
    // next("/app/sessions/sign-in-two");
  }
};
