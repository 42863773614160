const state = {
  userKey: null,
  userIndex: null,
};

const getters = {
  getUserKey: (state) => state.userKey,
  getUserIndex: (state) => state.userIndex,
};

const actions = {
  changeUserKey({ commit }, value) {
    commit("updateUserKey", value);
  },
  changeUserIndex({ commit }, value) {
    commit("updateUserIndex", value);
  },
};

const mutations = {
  updateUserKey: (state, value) => {
    state.userKey = value;
  },
  updateUserIndex: (state, value) => {
    state.userIndex = value;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
