import { levelPopup } from "./index";

/** 유저 레벨업 정보 보기*/
export const getLevelPopup = (reqData) => {
  return levelPopup
    .get(`/userLevelPopupR?userIndex=${reqData.userIndex || 0}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};
/** 유저 레벨업 팝업 닫기 이벤트 저장*/
export const postLevelPopup = (reqData) => {
  const req = {
    data: reqData,
  };
  return levelPopup
    .post("/userLevelPopupClose", req)
    .then((res) => {
      return res.data[0];
    })
    .catch((err) => {
      return err;
    });
};
