import { bodyInfo } from "./index";

export const getBodyInfo = (reqData) => {
  return bodyInfo
    .get(`/summary?userIndex=${reqData.userIndex || 0}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};
/** 성장 정보 조회 */
export const getLastBodyInfo = (reqData) => {
  return bodyInfo
    .get(`/lastBodyInfoR?userIndex=${reqData.userIndex || 0}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

/** 성장 기록 보기 */
export const getBodyInfoList = (reqData) => {
  return bodyInfo
    .get(
      `/bodyInfoList?userIndex=${reqData.userIndex ||
        0}&listRange=${reqData.listRange || "all"}`
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

// 성장 정보 저장
export const postBodyInfo = (reqData) => {
  const req = {
    data: reqData,
  };
  return bodyInfo
    .post("/bodyInfoS", req)
    .then((res) => {
      return res.data[0];
    })
    .catch((err) => {
      return err;
    });
};
/** 성장 기록 삭제 */
export const deleteBodyInfo = (reqData) => {
  const req = {
    data: reqData,
  };
  return bodyInfo
    .post("/bodyInfoD", req)
    .then((res) => {
      return res.data[0];
    })
    .catch((err) => {
      return err;
    });
};
