import { injectionLog } from "./index";
/** 투약 기록 조회 */
export const getInjectionLog = (reqData) => {
  return injectionLog
    .get(
      `/monthSummary?userIndex=${reqData.userIndex ||
        0}&targetYear=${reqData.targetYear ||
        0}&targetMonth=${reqData.targetMonth || 0}`
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};
/** 투약 로그 조회 (전체) */
export const getInjectionLogList = (reqData) => {
  return injectionLog
    .get(
      `/injectionLogList?userIndex=${reqData.userIndex ||
        0}&targetYear=${reqData.targetYear ||
        0}&targetMonth=${reqData.targetMonth || 0}`
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};
/** 투약 여부 조회 */
export const getInjectionCheck = (reqData) => {
  return injectionLog
    .get(
      `/injectionCheck?userIndex=${reqData.userIndex ||
        0}&targetDate=${reqData.targetDate || 0}`
    )
    .then((res) => {
      return res.data[0];
    })
    .catch((err) => {
      return err;
    });
};
/** 마지막 투약 부위 조회 */
export const getLastInjectionLog = (reqData) => {
  return injectionLog
    .get(
      `/lastInjectionLog?userIndex=${reqData.userIndex ||
        0}&targetDate=${reqData.targetDate || 0}`
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};
/** 투약 기록 저장*/
export const postInjectionLog = (reqData) => {
  const req = {
    data: reqData,
  };
  return injectionLog
    .post("/injectionLogS", req)
    .then((res) => {
      return res.data[0];
    })
    .catch((err) => {
      return err;
    });
};
/** 투약 기록 저장*/
export const postUpdateInjectionLog = (reqData) => {
  const req = {
    data: reqData,
  };
  return injectionLog
    .post("/injectionLogU", req)
    .then((res) => {
      return res.data[0];
    })
    .catch((err) => {
      return err;
    });
};

/** 투약 기록 상세 조회 */
export const getInjectionLogDetail = (reqData) => {
  return injectionLog
    .get(
      `/injectionLogR?userIndex=${reqData.userIndex ||
        0}&injectionIndex=${reqData.injectionIndex || 0}`
    )
    .then((res) => {
      return res.data[0];
    })
    .catch((err) => {
      return err;
    });
};
/** 최종 투약 기록 조회 */
export const getLastInjectionLogForPrescription = (reqData) => {
  return injectionLog
    .get(`/lastInjectionLogForPrescription?userIndex=${reqData.userIndex}`)
    .then((res) => {
      return res.data[0];
    })
    .catch((err) => {
      return err;
    });
};
