import store from "@/store/index";
import { getReportInjectionLog, getReportGrowRecordLog } from "@/api/report";

const state = {};

const getters = {};

const actions = {
  async getReportInjection({ commit }, reqData) {
    const userIndex = store.getters.getUserIndex;
    if (!userIndex) throw new Error("유저 인덱스가 없습니다.");
    reqData.userIndex = userIndex;
    const res = await getReportInjectionLog(reqData);
    if (res) {
      return res;
    } else {
      throw new Error("getReportInjection 요청에 실패했습니다.");
    }
  },
  async getReportGrowRecord({ commit }, reqData) {
    const userIndex = store.getters.getUserIndex;
    if (!userIndex) throw new Error("유저 인덱스가 없습니다.");
    reqData.userIndex = userIndex;
    const res = await getReportGrowRecordLog(reqData);
    if (res) {
      return res;
    } else {
      throw new Error("getReportGrowRecord 요청에 실패했습니다.");
    }
  },
};

const mutations = {
  //   updateUserKey: (state, id) => {
  //     state.userKey = id;
  //   },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
