import axios from "axios";
import store from "@/store/index";
import {
  getInjectionCheck,
  getInjectionLogDetail,
  getLastInjectionLog,
  postInjectionLog,
  postUpdateInjectionLog,
} from "@/api/injectionLog";

const state = {};

const getters = {};

const actions = {
  async getInjectionLog({ commit }, reqData) {
    const userIndex = store.getters.getUserIndex;
    if (!userIndex) throw new Error("유저 인덱스가 없습니다.");
    reqData.userIndex = userIndex;
    const res = await getInjectionCheck(reqData);
    if (res) {
      return res;
    } else {
      throw new Error("getInjectionCheck 요청에 실패했습니다.");
    }
  },
  async getInjectionDetail({ commit }, reqData) {
    const userIndex = store.getters.getUserIndex;
    if (!userIndex) throw new Error("유저 인덱스가 없습니다.");
    reqData.userIndex = userIndex;
    const res = await getInjectionLogDetail(reqData);
    if (res) {
      return res;
    } else {
      throw new Error("getInjectionLogDetail 요청에 실패했습니다.");
    }
  },
  async getLastInjection({ commit }, reqData) {
    const userIndex = store.getters.getUserIndex;
    if (!userIndex) throw new Error("유저 인덱스가 없습니다.");
    reqData.userIndex = userIndex;
    const res = await getLastInjectionLog(reqData);
    return res;
  },
  async postInjection({ commit }, reqData) {
    const userIndex = store.getters.getUserIndex;
    if (!userIndex) throw new Error("유저 인덱스가 없습니다.");
    reqData.userIndex = userIndex;
    const res = await postInjectionLog(reqData);
    if (res) {
      console.log(res);
    } else {
      throw new Error("postInjection 요청에 실패했습니다.");
    }
    return res;
  },
  async postUpdateInjection({ commit }, reqData) {
    const userIndex = store.getters.getUserIndex;
    if (!userIndex) throw new Error("유저 인덱스가 없습니다.");
    reqData.userIndex = userIndex;
    const res = await postUpdateInjectionLog(reqData);
    if (res) {
      console.log(res);
    } else {
      throw new Error("postInjection 요청에 실패했습니다.");
    }
    return res;
  },
};

const mutations = {
  //   updateUserKey: (state, id) => {
  //     state.userKey = id;
  //   },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
