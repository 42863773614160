import axios from "axios";
import store from "@/store/index";
import { getUser, postUser, postUserUpdate } from "@/api/user";
import { getLastBodyInfo, postBodyInfo, deleteBodyInfo } from "@/api/bodyInfo";
import { getLastPrescription, postPrescription } from "@/api/prescription";
import { getLastInjectionLogForPrescription } from "@/api/injectionLog";

const state = {};

const getters = {};

const actions = {
  async getMyInfo({ commit }) {
    const userIndex = store.getters.getUserIndex;
    if (!userIndex) throw new Error("유저 인덱스가 없습니다.");
    const res = await getUser({ userIndex });
    // console.log(res);
    if (res) {
      return res;
    } else {
      throw new Error("getMyInfo 요청에 실패했습니다.");
    }
  },
  async saveMyInfo({ commit }, reqData) {
    // 저장된 유저가 있을경우 update, 아닐경우 insert
    let res;
    if (store.getters.getUserIndex) {
      const userIndex = store.getters.getUserIndex;
      if (!userIndex) throw new Error("유저 인덱스가 없습니다.");
      reqData.userIndex = userIndex;
      res = await postUserUpdate(reqData);
    } else {
      const userKey = store.getters.getUserKey;
      if (!userKey) throw new Error("유저키가 없습니다.");
      reqData.userKey = userKey;
      res = await postUser(reqData);
    }
    console.log(res);
    if (res.error === "0") {
      return res.user_index;
    } else {
      return false;
    }
  },
  async getLastBodyInfoData({ commit }) {
    const userIndex = store.getters.getUserIndex;
    if (!userIndex) throw new Error("유저 인덱스가 없습니다.");
    const res = await getLastBodyInfo({ userIndex });
    console.log(res);
    if (res) {
      return res[0];
    } else {
      throw new Error("getLastBodyInfoData 요청에 실패했습니다.");
    }
  },
  async saveBodyInfo({ commit }, reqData) {
    const res = await postBodyInfo(reqData);
    console.log(res);
    if (res.error === "0") {
      return true;
    } else {
      return false;
    }
  },
  async getLastPrescriptionData({ commit }, reqData) {
    const userIndex = store.getters.getUserIndex;
    reqData.userIndex = userIndex;
    if (!userIndex) throw new Error("유저 인덱스가 없습니다.");
    const res = await getLastPrescription(reqData);
    console.log(res);
    if (res) {
      return res;
    } else {
      throw new Error("getLastPrescriptionData 요청에 실패했습니다.");
    }
  },
  async getLastInjectionForPrescription({ commit }) {
    const userIndex = store.getters.getUserIndex;
    if (!userIndex) throw new Error("유저 인덱스가 없습니다.");
    const res = await getLastInjectionLogForPrescription({ userIndex });
    console.log(res);
    return res;
    // if (res) {
    //   return res;
    // } else {
    //   throw new Error(
    //     "getLastInjectionLogForPrescription 요청에 실패했습니다."
    //   );
    // }
  },
  async savePrescription({ commit }, reqData) {
    const res = await postPrescription(reqData);
    console.log(res);
    // if (res.error === "0") {
    //   return true;
    // } else {
    //   return false;
    // }
  },
  async deleteBodyInfoData({ commit }, reqData) {
    const res = await deleteBodyInfo(reqData);
    console.log(res);
    if (res.error === "0") {
      return true;
    } else {
      return false;
    }
  },
};

const mutations = {
  //   updateUserKey: (state, id) => {
  //     state.userKey = id;
  //   },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
