import axios from "axios";
import { setInterceptors } from "./common/interceptors";

// was경로 && .env 3501처리
const wasRouter = "gd/";

// 액시오스 초기화 함수
function createInstance() {
  return axios.create({
    baseURL: process.env.VUE_APP_API_URL + wasRouter,
  });
}

// 액시오스 인증정보화 함께가야할 endpoint 함수
function createInstanceWithAuth(url) {
  const instance = axios.create({
    baseURL: `${process.env.VUE_APP_API_URL}${url}`,
  });
  return setInterceptors(instance);
}

export const instance = createInstance();
// mainPage
export const checkUserKey = createInstanceWithAuth(`${wasRouter}checkUserKey`);
export const injectionLog = createInstanceWithAuth(`${wasRouter}injectionLog`);
export const bodyInfo = createInstanceWithAuth(`${wasRouter}bodyInfo`);
export const levelPopup = createInstanceWithAuth(`${wasRouter}levelPopup`);
// myInfoPage
export const user = createInstanceWithAuth(`${wasRouter}user`);
export const prescription = createInstanceWithAuth(`${wasRouter}prescription`);
// alertSettingPage
export const injectionNoticeSetting = createInstanceWithAuth(
  `${wasRouter}injectionNoticeSetting`
);
// missionPage
export const injectionMission = createInstanceWithAuth(
  `${wasRouter}injectionMission`
);
// report
export const report = createInstanceWithAuth(`${wasRouter}report`);
