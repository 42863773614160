import axios from "axios";
import store from "@/store/index";
import { getLevelPopup, postLevelPopup } from "@/api/levelPopup";

const state = {};

const getters = {};

const actions = {
  async getLevelPopupData({ commit }) {
    const userIndex = store.getters.getUserIndex;
    if (!userIndex) throw new Error("유저 인덱스가 없습니다.");
    const res = await getLevelPopup({ userIndex });
    if (res) {
      return res;
    } else {
      throw new Error("getLevelPopupData 요청에 실패했습니다.");
    }
  },
  //   async getInjectionNoticeData({ commit }) {
  //     const userIndex = store.getters.getUserIndex;
  //     if (!userIndex) throw new Error("유저 인덱스가 없습니다.");
  //     const res = await getInjectionNoticeSetting({ userIndex });
  //     if (res) {
  //       return res;
  //     } else {
  //       throw new Error("getInjectionNoticeData 요청에 실패했습니다.");
  //     }
  //   },
  //   async postInsertInjectionNotice({ commit }, reqData) {
  //     const userIndex = store.getters.getUserIndex;
  //     if (!userIndex) throw new Error("유저 인덱스가 없습니다.");
  //     reqData.userIndex = userIndex;
  //     const res = await postInsertInjectionNoticeSetting(reqData);
  //     if (res) {
  //       console.log(res);
  //     } else {
  //       throw new Error("postInsertInjectionNotice 요청에 실패했습니다.");
  //     }
  //     return res;
  //   },
  //   async postUpdateInjectionNotice({ commit }, reqData) {
  //     const userIndex = store.getters.getUserIndex;
  //     if (!userIndex) throw new Error("유저 인덱스가 없습니다.");
  //     reqData.userIndex = userIndex;
  //     const res = await postUpdateInjectionNoticeSetting(reqData);
  //     if (res) {
  //       console.log(res);
  //     } else {
  //       throw new Error("postUpdateInjectionNotice 요청에 실패했습니다.");
  //     }
  //     return res;
  //   },
};

const mutations = {
  //   updateUserKey: (state, id) => {
  //     state.userKey = id;
  //   },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
