import { prescription } from "./index";
/** 처방 정보 조회*/
export const getLastPrescription = (reqData) => {
  return prescription
    .get(
      `/lastPrescriptionR?userIndex=${reqData.userIndex ||
        0}&targetYear=${reqData.targetYear ||
        null}&targetMonth=${reqData.targetMonth || null}`
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};
/** 처방 기록 보기*/
export const getPrescriptionList = (reqData) => {
  return prescription
    .get(`/prescriptionList?userIndex=${reqData.userIndex || 0}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};
/** 처방 정보 저장*/
export const postPrescription = (reqData) => {
  const req = {
    data: reqData,
  };
  return prescription
    .post("/prescriptionS", req)
    .then((res) => {
      return res.data[0];
    })
    .catch((err) => {
      return err;
    });
};
