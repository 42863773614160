import mock from "@/fake-db/mock.js";

// chat
const UserDB = {
  user: []
};

// mock.onGet("/api/chat/contacts").reply(() => {
//   const response = ChatDB.contacts;
//   return [200, response];
// });
// mock.onGet("/api/chat/chats").reply(() => {
//   const response = ChatDB.chats;
//   return [200, response];
// });

// mock.onGet("/api/chat/id").reply(config => {
//   // console.log(config.contactID, 'backend');
//   const admin = ChatDB.user;

//   // between user and contact table
//   const recentID = admin[0].chatInfo.find(
//     x => x.contactId === config.contactID
//   );

//   // between user and chat collection

//   const response = ChatDB.chatCollection.find(x => x.id == recentID.chatId);
//   // let fetchTwo = fetch.chats.map(x => x)

//   return [200, response];
// });

mock.onPost("/api/user").reply(config => {
  console.log(config);
  const user = {
    uid: "HO4FKSrU9Mex4twvFTCCZV2C8Y43"
  };
  return [200, user];
});
