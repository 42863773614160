import { report } from "./index";
/** 투약 기록 기간 조회 */
export const getReportInjectionLog = (reqData) => {
  return report
    .get(
      `/reportInjectionSummary?userIndex=${reqData.userIndex ||
        0}&fromYear=${reqData.fromYear || 0}&fromMonth=${reqData.fromMonth ||
        0}&toYear=${reqData.toYear || 0}&toMonth=${reqData.toMonth || 0}`
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};
/** 성장 기록 기간 조회 (전체) */
export const getReportGrowRecordLog = (reqData) => {
  return report
    .get(
      `/reportBodyInfoSummary?userIndex=${reqData.userIndex ||
        0}&fromYear=${reqData.fromYear || 0}&fromMonth=${reqData.fromMonth ||
        0}&toYear=${reqData.toYear || 0}&toMonth=${reqData.toMonth || 0}`
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};
